
import { defineComponent, reactive, ref } from 'vue';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { RegistrationDetails } from '@/models/Models';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

export default defineComponent({
    props: {
        token: {
            type: String,
            required: true
        }
    },
    components: {
        LanguageSwitcher
    },
    async setup(props: any) {
        let isSubmitFormActive = false;
        const { t, locale } = useI18n();
        const confirmationResponse = await api.confirmEmail(props.token);
        if (confirmationResponse.errorMessage) {
            swal.fire({
                icon: 'error',
                text: confirmationResponse.errorMessage
            });
            router.push({ name: 'login' });
            return;
        }

        const agreeCheckbox = ref(false);
        const isAgree = ref(false);
        const isGeneralAgreementPage = ref(false);
        const providerLogo = ref();

        const details = reactive(new RegistrationDetails());

        //default values takes from first step registration
        const providerData = confirmationResponse.data;
        if (providerData) {
            details.phoneNumber = providerData.phoneNumber;
            details.financeDepartmentPhoneNumber = providerData.phoneNumber;
            details.operationContactPhoneNumber = providerData.phoneNumber;
            details.businessEmail = providerData.email;
            details.financeDepartmentEmail = providerData.email;
            details.operationContactEmail = providerData.email;
            details.operationContactFullName = providerData.fullName;
            details.financeDepartmentFullName = providerData.fullName;
            details.providerName = providerData.companyName;
        }

        const submitForm = async (event: any) => {
            if (isSubmitFormActive) return;
            isSubmitFormActive = true;
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                isSubmitFormActive = false;
                event.preventDefault();
                event.stopPropagation();
            } else {
                swal.showLoading();
                details.token = props.token;
                const userDetailsResponse = await api.registrationDetails(details);
                isSubmitFormActive = false;
                if (userDetailsResponse.data?.errorCode == 801) {
                    swal.fire({
                        icon: 'error',
                        title: t('error-pop-up.oops'),
                        text: t('registration.errorMessage.companyIdExist')
                    });
                    return;
                }
                if (userDetailsResponse.hasValidationError && userDetailsResponse.validationErrorsList()?.length) {
                    const message = userDetailsResponse
                        .validationErrorsList()
                        ?.map(x => `Field '${x?.key}': ${x?.msg}`)
                        ?.join('\n');
                    swal.fire({
                        icon: 'error',
                        text: message
                    });
                } else if (userDetailsResponse.errorMessage)
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: userDetailsResponse.errorMessage });
                else if (userDetailsResponse.data && userDetailsResponse.data.userId != '0') {
                    swal.fire({
                        icon: 'success',
                        title: t('registration.wellDone'),
                        text: t('registration.youAreNowBookitMember'),
                        confirmButtonText: t('registration.startNow')
                    }).then(() => {
                        router.push({ name: 'login' });
                    });
                    return;
                }
            }
        };

        const scrollToTop = () => {
            document?.getElementById('registration-container')?.scrollIntoView();
        };

        const uploadingImageValidation = async (e: any) => {
            swal.showLoading();
            let file = e.target.files[0];
            const fileTypes: string[] = ['image/jpeg', 'image/png'];
            if (file) {
                if (file.size > 5242880) {
                    swal.fire({
                        icon: 'error',
                        text: 'File size should be less or equal to 5 MB'
                    });
                    file = null;
                    swal.close();
                    return;
                }
                if (!fileTypes.find(t => t === file.type)) {
                    swal.fire({
                        icon: 'error',
                        text: 'Allowing file types: jpeg, jpg, png'
                    });
                    file = null;
                    swal.close();
                    return;
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event: any) => {
                        providerLogo.value = event.target.result;
                        details.logo = event.target.result.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '');
                    };
                    reader.readAsDataURL(file);
                }
            }
            swal.close();
        };

        const mobileNumberChanged = (type: number) => {
            switch (type) {
                case 1:
                    details.phoneNumber = details.phoneNumber.replace(/[^\s+\-0-9]/g, '');
                    break;
                case 2:
                    details.operationContactPhoneNumber = details.operationContactPhoneNumber.replace(/[^\s+\-0-9]/g, '');
                    break;
                case 3:
                    details.financeDepartmentPhoneNumber = details.financeDepartmentPhoneNumber.replace(/[^\s+\-0-9]/g, '');
                    break;
            }
        }

        return {
            mobileNumberChanged,
            submitForm,
            details,
            agreeCheckbox,
            isAgree,
            isGeneralAgreementPage,
            scrollToTop,
            uploadingImageValidation,
            providerLogo,
            locale
        };
    }
});
